var render = function render(){
  var _vm$userData, _vm$userData$user;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "applicant"
  }, [_c('div', {
    staticClass: "flex justify-between items-center pb-6 gap-6 shadow-border-b mdmax:flex-col mdmax:shadow-none"
  }, [_c('div', {
    staticClass: "w-2/6 mdmax:flex-auto mdmax:w-full mdmax:mb-2"
  }, [_c('p', {
    staticClass: "text-2xl font-bold"
  }, [_vm._v(_vm._s((_vm$userData = _vm.userData) === null || _vm$userData === void 0 ? void 0 : (_vm$userData$user = _vm$userData.user) === null || _vm$userData$user === void 0 ? void 0 : _vm$userData$user.group))])]), _c('div', {
    staticClass: "w-4/6 flex justify-end gap-6 mdmax:flex-col mdmax:flex-auto mdmax:w-full mdmax:gap-2"
  }, [_c('div', {
    staticClass: "mdmax:w-full"
  }, [_c('LinkButton', {
    attrs: {
      "buttonText": "Tambah Pemohon",
      "url": `/applicant/add`,
      "linkType": "internal",
      "type": "primary",
      "size": _vm.isMobile ? 'big_full' : 'big'
    }
  })], 1), _c('div', {
    staticClass: "mdmax:w-full"
  }, [_c('div', {
    staticClass: "date-range relative"
  }, [_c('div', {
    staticClass: "absolute left-3 top-1/2 transform -translate-y-1/2 z-1"
  }, [_c('Calendar', {
    attrs: {
      "color": "neutral-700"
    }
  })], 1), _c('date-range-picker', {
    attrs: {
      "showDropdowns": false,
      "timePicker": false,
      "timePicker24Hour": false,
      "ranges": false
    },
    on: {
      "update": function ($event) {
        return _vm.getList();
      }
    },
    model: {
      value: _vm.dateRange,
      callback: function ($$v) {
        _vm.dateRange = $$v;
      },
      expression: "dateRange"
    }
  })], 1)]), _c('div', {
    staticClass: "mdmax:w-full"
  }, [_c('div', {
    staticClass: "relative"
  }, [_c('TextField', {
    attrs: {
      "type": "text",
      "withIcon": true,
      "placeholder": "Cari data"
    },
    on: {
      "enter": function ($event) {
        return _vm.doSearch();
      }
    },
    model: {
      value: _vm.searchKeyword,
      callback: function ($$v) {
        _vm.searchKeyword = $$v;
      },
      expression: "searchKeyword"
    }
  }), _c('div', {
    staticClass: "absolute left-3 top-1/2 transform -translate-y-1/2"
  }, [_c('Search')], 1)], 1)])])]), _c('TableComponent', {
    attrs: {
      "columns": _vm.columns,
      "list": _vm.applicantList
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function (_ref) {
        var _entry$latest_test_sc, _entry$latest_test_sc2, _entry$latest_test_sc3;
        var entry = _ref.entry,
          column = _ref.column;
        return [column.field === 'action' ? _c('div', {
          staticClass: "space-y-2"
        }, [_c('LinkButton', {
          attrs: {
            "buttonText": "Lihat rincian",
            "url": `/applicant/detail/${entry.id}`,
            "linkType": "internal",
            "type": "secondary",
            "size": "small"
          }
        }), _c('Button', {
          attrs: {
            "buttonText": "Hapus Data",
            "type": "danger",
            "size": "xsmall"
          },
          on: {
            "action": function ($event) {
              return _vm.deleteData(entry.user_id);
            }
          }
        })], 1) : column.field === 'date' ? _c('div', {
          staticClass: "whitespace-nowrap"
        }, [_vm._v(" " + _vm._s(_vm._f("formatDate")(entry === null || entry === void 0 ? void 0 : (_entry$latest_test_sc = entry.latest_test_schedule) === null || _entry$latest_test_sc === void 0 ? void 0 : _entry$latest_test_sc.schedule_time)) + " ")]) : column.field === 'time' ? _c('div', [_vm._v(" " + _vm._s(_vm._f("formatTime")(entry === null || entry === void 0 ? void 0 : (_entry$latest_test_sc2 = entry.latest_test_schedule) === null || _entry$latest_test_sc2 === void 0 ? void 0 : _entry$latest_test_sc2.schedule_time)) + " ")]) : column.field === 'location' ? _c('div', [_vm._v(" " + _vm._s(entry === null || entry === void 0 ? void 0 : (_entry$latest_test_sc3 = entry.latest_test_schedule) === null || _entry$latest_test_sc3 === void 0 ? void 0 : _entry$latest_test_sc3.location_test_display) + " ")]) : _c('span', [_vm._v(_vm._s(entry[column.field]))])];
      }
    }])
  }), _vm.applicantList && _vm.applicantList.length > 0 ? _c('div', {
    staticClass: "mt-8 flex justify-end items-center mb-6"
  }, [_c('pagination', {
    attrs: {
      "currentPage": _vm.currentPage,
      "totalCount": _vm.totalRecords,
      "pageSize": _vm.pageSize
    },
    on: {
      "onPageChange": _vm.onPageChange
    }
  })], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }